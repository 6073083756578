import { mixins, Pie } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
   name: 'ObiPieChart',
   extends: Pie,
   mixins: [reactiveProp],
   props: ['options'],
   mounted() {
      this.renderChart(this.chartData, this.options);
   },
};
